
/* Form products */
.card-option {
    display: block;
    text-align: center;
    border: 1px solid var(--light-secondary-white-3);
    border-radius: 8px;
    margin: 10px 5px;
    box-shadow: 2px 2px 12px 0px var(--light-secondary-white-7);
    position: relative;
    overflow: visible;
}
.card-option.selected {
    border: 2px solid var(--light-primary-blue-2);
    transform: scale(1.1);
}

.card-option-img-content {
    display: flex;
    padding: 0px;
    overflow: hidden;
    width: 100%;
    align-items: center;
    justify-content: center;
}
img.card-option-img {
    display: block;
    width: 90%;
}
.card-option-content {
    display: flex;
    min-height: 45px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    background: var(--light-primary-white-3);
    font-weight: 600;
}

/* Contenedor del ícono de información */
.info-icon {
    position: absolute;
    top: 0px;
    right: 5px;
    font-size: 18px;
    color: var(--light-secondary-white-7);
    cursor: pointer;
  }

  /* Tooltip oculto por defecto */
.info-icon .tooltip {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 5px;
    right: 0;
    background-color: var(--light-secondary-white-7);
    color: var(--light-primary-black-1);
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 12px;
    min-width: 100px;
    white-space: normal;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease;
    z-index: 100;
    word-wrap: break-word;
  }
  
  /* Mostrar tooltip al pasar el mouse */
  .info-icon:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  @media (min-width: 793px) and (max-width: 900px) {
    .tooltip {
      font-size: 10px !important;
      min-width: 70px !important;
    }
  }

/* dropdown */
.dropdown-item{
    margin: 3px;
    border-bottom: 1px solid var(--light-primary-white-2);
}
/* main header */
.btn-action-dash span.material-symbols-outlined {
    position: relative;
    top: 3px;
    font-size: 22px;
}
.actions-dashboard {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
span.location-label {
    font-size: 13px;
    color: var(--light-primary-gray-4);
    line-height: 13px;
}

/* table */
.bold-table-note {
    max-width: 920px;
    padding: 0px 20px;
    margin: 10px 0px;
}

/* btn table actions*/
.btn-table-actions.wx2 {
    width: 52px;
    position: relative;
}
span.xsmall {
    font-size: 8px !important;
    position: absolute;
    top: 20px;
    left: 0px;
    right: 0;
}


/* card products selected in form */
.card-option-selected {
    border: 2px solid var(--light-primary-red-3);
}

.btn-linked:visited {
    color: '#997404'
}

.small-text{
    font-size: 11px !important;
}

html {
    ::-webkit-scrollbar {
        width: 10px;
      }
    
    ::-webkit-scrollbar-track {
        background: transparent;
      }
    
    ::-webkit-scrollbar-thumb {
        background-color: var(--light-secondary-light-gray-3);
        border-radius: 17px;
        border: 0px solid var(--light-primary-white-1);
    };
  }

.aside {
    ::-webkit-scrollbar {
        width: 5px;
      }
    
    ::-webkit-scrollbar-track {
        background: transparent;
      }
    
    ::-webkit-scrollbar-thumb {
        background-color: var(--light-primary-gray-3);
        border-radius: 17px;
        border: 0px solid var(--light-primary-white-1);
    };
  }
  @-moz-document url-prefix() {
    html {
        scrollbar-width: thin;
        scrollbar-color: var(--light-secondary-light-gray-3) transparent;
      }
  }

  .d-flex-modal{
    display: flex !important;
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

@media(max-width:1350px) {
    .d-flex-modal{
        display: block !important;
        margin-right: 0rem !important;
        margin-left: 0rem !important;
    }
}
.boton-facturas {
    background-color: #E0E0E0;
    color: var(--light-secondary-night-blue);
    font-size: 14px;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
  }
  
  .boton-facturas:hover {
    background-color: #CCCCCC;
  }

  .dark-boton-facturas {
    background-color: #424242;
    color: var(--dark-secondary-light-gray-1);
    font-size: 14px;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
  }
  
  .dark-boton-facturas:hover {
    background-color: #949292;
  }